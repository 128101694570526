import { useLocation } from '@reach/router';
import { useFormik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'ui-kit-v2/button/button';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import { Text } from 'ui-kit-v2/text/text';

import ToastBox from 'ui-kit/toast-box/toast-box';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { MOBILE_PHONE_SETUP_VALIDATION } from 'schema/mobile-phone-setup.schema';

import { formatPhoneNumber, removeChars } from 'util/string';

import './mobile-phone-setup.styles.scss';

const OnboardingMobilePhoneSetup = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phoneNumber = queryParams.get('phoneNumber') || '';

    const initialValues = {
        phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber, 'parentheses') : ''
    };

    const formik = useFormik({
        initialValues,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: MOBILE_PHONE_SETUP_VALIDATION(t),
        onSubmit: (values) => {
            verifyPhoneNumberBySMS(values.phoneNumber);
        }
    });

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = formatPhoneNumber(event.target.value, 'parentheses');
        formik.setFieldValue('phoneNumber', formattedPhone);
    };

    const verifyPhoneNumberBySMS = async (phoneNumber: string) => {
        formik.setSubmitting(true);
        await formik.validateForm();
        navigate('/secure/onboarding/mobile-phone-validation-setup?phoneNumber=' + removeChars(phoneNumber));
    };

    return (
        <WorkflowLayout
            className="mobile-phone-setup-page"
            metaData={{ nodeTitle: 'Mobile Phone Setup' }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                title={t('pages.onboarding.mobilePhoneSetup.title')}
                copyText={t('pages.onboarding.mobilePhoneSetup.subtitle')}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={4}
                        usage={5}
                        customColor="picton-blue"
                        handleClick={() => {
                            // TODO: navigate to the previous page
                        }}
                    />
                }
            >
                <form className="mobile-phone-setup-page__container" onSubmit={formik.handleSubmit} noValidate>
                    <TextInputComponent
                        {...formik.getFieldProps('phoneNumber')}
                        label={t('pages.onboarding.mobilePhoneSetup.form.phone.label')}
                        onlyNumbers
                        maxLength={10}
                        onChange={handlePhoneChange}
                        error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    />

                    <ToastBox variant="info" icon="info" iconColor="ebony-clay">
                        <Text size="num-1_75x" lineHeight={21} color="ebony-clay">
                            {t('pages.onboarding.mobilePhoneSetup.form.phone.info')}
                        </Text>
                    </ToastBox>

                    <ButtonComponent
                        className="mobile-phone-setup-page__button"
                        isLoading={formik.isSubmitting}
                        type="submit"
                        variant="solid"
                        color="primary"
                        size="lg"
                        label={t('pages.onboarding.mobilePhoneSetup.form.submit')}
                    />
                </form>

                <Text size="num-1_75x" color="dusk" lineHeight={21}>
                    {t('pages.onboarding.mobilePhoneSetup.footerInfo')}
                </Text>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default OnboardingMobilePhoneSetup;

export const query = graphql`
    query OnboardingMobilePhoneSetupPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
